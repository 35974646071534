export const STORE_LIST_REQUEST = "STORE_LIST_REQUEST";
export const STORE_LIST_SUCCESS = "STORE_LIST_SUCCESS";
export const STORE_LIST_FAIL = "STORE_LIST_FAIL";
export const STORE_LIST_RESET = "STORE_LIST_RESET";

export const STORE_SEARCH_SUCCESS = "STORE_SEARCH_SUCCESS";

export const STORE_MY_LIST_REQUEST = "STORE_MY_LIST_REQUEST";
export const STORE_MY_LIST_SUCCESS = "STORE_MY_LIST_SUCCESS";
export const STORE_MY_LIST_FAIL = "STORE_MY_LIST_FAIL";

export const STORE_CREATE_REQUEST = "STORE_CREATE_REQUEST";
export const STORE_CREATE_SUCCESS = "STORE_CREATE_SUCCESS";
export const STORE_CREATE_FAIL = "STORE_CREATE_FAIL";
export const STORE_CREATE_RESET = "STORE_CREATE_RESET";

export const STORE_DELETE_REQUEST = "STORE_DELETE_REQUEST";
export const STORE_DELETE_SUCCESS = "STORE_DELETE_SUCCESS";
export const STORE_DELETE_FAIL = "STORE_DELETE_FAIL";
export const STORE_DELETE_RESET = "STORE_DELETE_RESET";

export const STORE_DETAILS_REQUEST = "STORE_DETAILS_REQUEST";
export const STORE_DETAILS_SUCCESS = "STORE_DETAILS_SUCCESS";
export const STORE_DETAILS_FAIL = "STORE_DETAILS_FAIL";

export const STORE_STOCKS_REQUEST = "STORE_STOCKS_REQUEST";
export const STORE_STOCKS_SUCCESS = "STORE_STOCKS_SUCCESS";
export const STORE_STOCKS_FAIL = "STORE_STOCKS_FAIL";
export const STORE_STOCKS_RESET = "STORE_STOCKS_RESET";

export const STORES_BY_USER_REQUEST = "STORES_BY_USER_REQUEST";
export const STORES_BY_USER_SUCCESS = "STORES_BY_USER_SUCCESS";
export const STORES_BY_USER_FAIL = "STORES_BY_USER_FAIL";
