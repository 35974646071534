export const STOCK_UPDATE_REQUEST = "STOCK_UPDATE_REQUEST";
export const STOCK_UPDATE_SUCCESS = "STOCK_UPDATE_SUCCESS";
export const STOCK_UPDATE_FAIL = "STOCK_UPDATE_FAIL";
export const STOCK_UPDATE_RESET = "STOCK_UPDATE_RESET";

export const STOCK_CREATE_REQUEST = "STOCK_CREATE_REQUEST";
export const STOCK_CREATE_SUCCESS = "STOCK_CREATE_SUCCESS";
export const STOCK_CREATE_FAIL = "STOCK_CREATE_FAIL";
export const STOCK_CREATE_RESET = "STOCK_CREATE_RESET";

export const STOCK_LIST_REQUEST = "STOCK_LIST_REQUEST";
export const STOCK_LIST_SUCCESS = "STOCK_LIST_SUCCESS";
export const STOCK_LIST_FAIL = "STOCK_LIST_FAIL";

export const PRODUCT_STOCKS_REQUEST = "PRODUCT_STOCKS_REQUEST";
export const PRODUCT_STOCKS_SUCCESS = "PRODUCT_STOCKS_SUCCESS";
export const PRODUCT_STOCKS_FAIL = "PRODUCT_STOCKS_FAIL";
export const PRODUCT_STOCKS_RESET = "PRODUCT_STOCKS_RESET";

export const STOCK_SEARCH_SUCCESS = "STOCK_SEARCH_SUCCESS";
