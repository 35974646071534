export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";
export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";
export const ORDER_DETAILS_RESET = "ORDER_DETAILS_RESET";

export const SELLER_ORDER_DETAILS_REQUEST = "SELLER_ORDER_DETAILS_REQUEST";
export const SELLER_ORDER_DETAILS_SUCCESS = "SELLER_ORDER_DETAILS_SUCCESS";
export const SELLER_ORDER_DETAILS_FAIL = "SELLER_ORDER_DETAILS_FAIL";
export const SELLER_ORDER_DETAILS_RESET = "SELLER_ORDER_DETAILS_RESET";

export const ORDER_PAY_REQUEST = "ORDER_PAY_REQUEST";
export const ORDER_PAY_SUCCESS = "ORDER_PAY_SUCCESS";
export const ORDER_PAY_FAIL = "ORDER_PAY_FAIL";
export const ORDER_PAY_RESET = "ORDER_PAY_RESET";

export const SELLER_ORDER_COMPLETE_REQUEST = "SELLER_ORDER_COMPLETE_REQUEST";
export const SELLER_ORDER_COMPLETE_SUCCESS = "SELLER_ORDER_COMPLETE_SUCCESS";
export const SELLER_ORDER_COMPLETE_FAIL = "SELLER_ORDER_COMPLETE_FAIL";
export const SELLER_ORDER_COMPLETE_RESET = "SELLER_ORDER_COMPLETE_RESET";

export const ORDER_LIST_MY_REQUEST = "ORDER_LIST_MY_REQUEST";
export const ORDER_LIST_MY_SUCCESS = "ORDER_LIST_MY_SUCCESS";
export const ORDER_LIST_MY_FAIL = "ORDER_LIST_MY_FAIL";
export const ORDER_LIST_MY_RESET = "ORDER_LIST_MY_RESET";

export const SELLER_ORDER_LIST_MY_REQUEST = "SELLER_ORDER_LIST_MY_REQUEST";
export const SELLER_ORDER_LIST_MY_SUCCESS = "SELLER_ORDER_LIST_MY_SUCCESS";
export const SELLER_ORDER_LIST_MY_FAIL = "SELLER_ORDER_LIST_MY_FAIL";
export const SELLER_ORDER_LIST_MY_RESET = "SELLER_ORDER_LIST_MY_RESET";

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";

export const SELLER_ORDER_LIST_REQUEST = "SELLER_ORDER_LIST_REQUEST";
export const SELLER_ORDER_LIST_SUCCESS = "SELLER_ORDER_LIST_SUCCESS";
export const SELLER_ORDER_LIST_FAIL = "SELLER_ORDER_LIST_FAIL";

export const SELLER_ORDER_SEND_REQUEST = "SELLER_ORDER_SEND_REQUEST";
export const SELLER_ORDER_SEND_SUCCESS = "SELLER_ORDER_SEND_SUCCESS";
export const SELLER_ORDER_SEND_FAIL = "SELLER_ORDER_SEND_FAIL";
export const SELLER_ORDER_SEND_RESET = "SELLER_ORDER_SEND_RESET";

export const SELLER_ORDER_RETRIEVE_REQUEST = "SELLER_ORDER_RETRIEVE_REQUEST";
export const SELLER_ORDER_RETRIEVE_SUCCESS = "SELLER_ORDER_RETRIEVE_SUCCESS";
export const SELLER_ORDER_RETRIEVE_FAIL = "SELLER_ORDER_RETRIEVE_FAIL";
export const SELLER_ORDER_RETRIEVE_RESET = "SELLER_ORDER_RETRIEVE_RESET";

export const ORDER_SEND_REQUEST = "ORDER_SEND_REQUEST";
export const ORDER_SEND_SUCCESS = "ORDER_SEND_SUCCESS";
export const ORDER_SEND_FAIL = "ORDER_SEND_FAIL";
export const ORDER_SEND_RESET = "ORDER_SEND_RESET";
