export const STORE_SEARCH_REQUEST = "STORE_SEARCH_REQUEST";
export const STORE_SEARCH_SUCCESS = "STORE_SEARCH_SUCCESS";
export const STORE_SEARCH_FAIL = "STORE_SEARCH_FAIL";

export const PRODUCT_SEARCH_REQUEST = "PRODUCT_SEARCH_REQUEST";
export const PRODUCT_SEARCH_SUCCESS = "PRODUCT_SEARCH_SUCCESS";
export const PRODUCT_SEARCH_FAIL = "PRODUCT_SEARCH_FAIL";

export const PROFILE_SEARCH_REQUEST = "PROFILE_SEARCH_REQUEST";
export const PROFILE_SEARCH_SUCCESS = "PROFILE_SEARCH_SUCCESS";
export const PROFILE_SEARCH_FAIL = "PROFILE_SEARCH_FAIL";

export const MY_PRODUCTS_SEARCH_REQUEST = "MY_PRODUCTS_SEARCH_REQUEST";
export const MY_PRODUCTS_SEARCH_SUCCESS = "MY_PRODUCTS_SEARCH_SUCCESS";
export const MY_PRODUCTS_SEARCH_FAIL = "MY_PRODUCTS_SEARCH_FAIL";

export const MY_STORES_SEARCH_REQUEST = "MY_STORES_SEARCH_REQUEST";
export const MY_STORES_SEARCH_SUCCESS = "MY_STORES_SEARCH_SUCCESS";
export const MY_STORES_SEARCH_FAIL = "MY_STORES_SEARCH_FAIL";

export const MY_STORES_LIST_SUCCESS = "MY_STORES_LIST_SUCCESS";

export const STOCK_SEARCH_REQUEST = "STOCK_SEARCH_REQUEST";
export const STOCK_SEARCH_SUCCESS = "STOCK_SEARCH_SUCCESS";
export const STOCK_SEARCH_FAIL = "STOCK_SEARCH_FAIL";

export const STORE_STOCKS_SEARCH_REQUEST = "STORE_STOCKS_SEARCH_REQUEST";
export const STORE_STOCKS_SEARCH_SUCCESS = "STORE_STOCKS_SEARCH_SUCCESS";
export const STORE_STOCKS_SEARCH_FAIL = "STORE_STOCKS_SEARCH_FAIL";

export const PRODUCTS_BY_USER_REQUEST = "PRODUCTS_BY_USER_REQUEST";
export const PRODUCTS_BY_USER_SUCCESS = "PRODUCTS_BY_USER_SUCCESS";
export const PRODUCTS_BY_USER_FAIL = "PRODUCTS_BY_USER_FAIL";
