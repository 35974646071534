import { baseUrl } from "../constants";
import axios from "axios";
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_CLEAR_ITEMS,
} from "../constants/cartConstants";

export const addToCart =
  (id, quantity, orderType, productStock, stockId, storeName, storeId) =>
  async (dispatch, getState) => {
    const { data } = await axios.get(`${baseUrl}/api/products/${id}`);

    dispatch({
      type: CART_ADD_ITEM,
      payload: {
        id: data.id,
        brand: data.brand,
        name: data.name,
        image: data.image,
        price: data.price,
        stocks: data.stocks,
        quantity,
        storeName,
        productStock,
        stockId,
        storeId,
        seller: data.sellerDetails.name,
        sellerId: data.sellerDetails.id,
        orderType,
      },
    });
    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  };

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });

  const itemRemoved = getState().cart.cartItems.filter(
    (item) => item.id !== id
  );

  itemRemoved.length === 0
    ? localStorage.removeItem("cartItems")
    : localStorage.setItem("cartItems", JSON.stringify(itemRemoved));
};

export const emptyCart = () => (dispatch) => {
  dispatch({
    type: CART_CLEAR_ITEMS,
  });

  localStorage.removeItem("cartItems");
};

export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });

  localStorage.setItem("shippingAddress", JSON.stringify(data));
};

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  });

  localStorage.setItem("paymentMethod", JSON.stringify(data));
};
