export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";
export const PRODUCT_LIST_RESET = "PRODUCT_LIST_RESET";

export const REVIEW_LIST_REQUEST = "REVIEW_LIST_REQUEST";
export const REVIEW_LIST_SUCCESS = "REVIEW_LIST_SUCCESS";
export const REVIEW_LIST_FAIL = "REVIEW_LIST_FAIL";

export const PRODUCT_MY_LIST_REQUEST = "PRODUCT_MY_LIST_REQUEST";
export const PRODUCT_MY_LIST_SUCCESS = "PRODUCT_MY_LIST_SUCCESS";
export const PRODUCT_MY_LIST_FAIL = "PRODUCT_MY_LIST_FAIL";

export const LATEST_PRODUCTS_LIST_REQUEST = "LATEST_PRODUCTS_LIST_REQUEST";
export const LATEST_PRODUCTS_LIST_SUCCESS = "LATEST_PRODUCTS_LIST_SUCCESS";
export const LATEST_PRODUCTS_LIST_FAIL = "LATEST_PRODUCTS_LIST_FAIL";

export const LATEST_REVIEWS_LIST_REQUEST = "LATEST_REVIEWS_LIST_REQUEST";
export const LATEST_REVIEWS_LIST_SUCCESS = "LATEST_REVIEWS_LIST_SUCCESS";
export const LATEST_REVIEWS_LIST_FAIL = "LATEST_REVIEWS_LIST_FAIL";

export const PRODUCT_SEARCH_SUCCESS = "PRODUCT_SEARCH_SUCCESS";
export const MY_PRODUCTS_SEARCH_SUCCESS = "MY_PRODUCTS_SEARCH_SUCCESS";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";
export const PRODUCT_DETAILS_RESET = "PRODUCT_DETAILS_RESET";

export const PRODUCT_REVIEWS_REQUEST = "PRODUCT_REVIEWS_REQUEST";
export const PRODUCT_REVIEWS_SUCCESS = "PRODUCT_REVIEWS_SUCCESS";
export const PRODUCT_REVIEWS_FAIL = "PRODUCT_REVIEWS_FAIL";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";
export const PRODUCT_DELETE_RESET = "PRODUCT_DELETE_RESET";

export const PRODUCT_CREATE_REQUEST = "PRODUCT_CREATE_REQUEST";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAIL = "PRODUCT_CREATE_FAIL";
export const PRODUCT_CREATE_RESET = "PRODUCT_CREATE_RESET";

export const PRODUCT_CREATE_REVIEW_REQUEST = "PRODUCT_CREATE_REVIEW_REQUEST";
export const PRODUCT_CREATE_REVIEW_SUCCESS = "PRODUCT_CREATE_REVIEW_SUCCESS";
export const PRODUCT_CREATE_REVIEW_FAIL = "PRODUCT_CREATE_REVIEW_FAIL";
export const PRODUCT_CREATE_REVIEW_RESET = "PRODUCT_CREATE_REVIEW_RESET";

export const PRODUCTS_BY_USER_REQUEST = "PRODUCTS_BY_USER_REQUEST";
export const PRODUCTS_BY_USER_SUCCESS = "PRODUCTS_BY_USER_SUCCESS";
export const PRODUCTS_BY_USER_FAIL = "PRODUCTS_BY_USER_FAIL";
