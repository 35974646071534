export const PRODUCT_CATEGORIES_LIST_REQUEST =
  "PRODUCT_CATEGORIES_LIST_REQUEST";
export const PRODUCT_CATEGORIES_LIST_SUCCESS =
  "PRODUCT_CATEGORIES_LIST_SUCCESS";
export const PRODUCT_CATEGORIES_LIST_FAIL = "PRODUCT_CATEGORIES_LIST_FAIL";

export const PRODUCT_SUBCATEGORIES_LIST_REQUEST =
  "PRODUCT_SUBCATEGORIES_LIST_REQUEST";
export const PRODUCT_SUBCATEGORIES_LIST_SUCCESS =
  "PRODUCT_SUBCATEGORIES_LIST_SUCCESS";
export const PRODUCT_SUBCATEGORIES_LIST_FAIL =
  "PRODUCT_SUBCATEGORIES_LIST_FAIL";
